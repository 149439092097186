#wit-list {
  max-width: 768px;
}

#wit-list .mw-40 {
  max-width: 40%;
}

#wit-list .carousel_ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  flex-grow: 0;
}

#wit-list .hover_container {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

#wit-list .hover_container:hover {
  transition: 0.3s;
  opacity: 1;
  color: white;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 25%,
    #532b71 95%
  );
}

@media (max-width: 620px) {
  #wit-list .hover_container:hover {
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 10%,
      #532b71 95%
    );
  }
}

#wit-list .hover_container_inner {
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px;
}

@media (max-width: 620px) {
  #wit-list .hover_container_inner {
    font-size: x-small;
  }
}

#wit-list .carousel_name {
  font-size: 1.1rem;
  font-weight: 600;
}

#wit-list .carousel_position {
  font-weight: 200;
  padding-bottom: 5%;
}

#wit-list .carousel_description_container {
  position: relative;
  overflow-y: hidden;
  flex-grow: 1;
}

#wit-list .carousel_description {
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: lighter;
}

#wit-list .carousel_description_hide {
  width: 100%;
  height: max(30px, 20%);
  bottom: 0px;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgb(83, 43, 113, 1) 5%,
    rgb(255, 255, 255, 0)
  );
}

#wit-list .carousel_read_more_link {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 3%;
  color: white;
  text-decoration: none;

  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 5px;
  margin-top: auto;
}

#wit-list .filter_container {
  color: white;
  background-color: rgb(83, 43, 113, 1);
  margin-left: 0px;
  margin-right: 0px;
}

#wit-list .filter_by {
  font-size: 0.9rem;
  font-weight: 600;
  column-gap: 1.5rem;
  white-space: nowrap;
  display: flex;
}

@media (max-width: 620px) {
  #wit-list .filter_container {
    padding-left: 2%;
    padding-right: 2%;
  }

  #wit-list .filter_by {
    font-size: 0.85rem;
    column-gap: 0.8rem;
    padding-left: 2%;
    padding-right: 2%;
  }
}

#wit-list .search_icon {
  color: rgb(83, 43, 113, 1);
  right: 5%;
  margin-right: 10px;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
}

#wit-list .search_input::placeholder {
  opacity: 0.7;
}

#wit-list .marr-5 {
  margin-right: 5%;
  text-decoration: underline;
}

#wit-list .filter_icon {
  color: rgb(225, 35, 100, 1);
}

#wit-list .carousel_item {
  height: 100%;
}

/* #wit-list .test{
    padding-left: 20px;
    padding-right: 20px;
  } */

#wit-list .test::after {
  width: 1.25rem;
  height: 1.25rem;
  background-size: 1.25rem;
  content: "";
  background-image: none !important;
}

#wit-list table,
th,
td {
  padding-right: 2%;
}

#wit-list th {
  text-align: left;
}

#wit-list .details {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #532b71;
  border-top-style: solid;
  border-top-color: #e12364;
  border-top-width: 8px;
  padding-left: 20px;
  padding-right: 20px;
}
#wit-list .details-header {
  display: flex;
  flex-direction: row;
}

@media (max-width: 375px) {
  #wit-list .details-header {
    display: unset;
  }
}

#wit-list .details-desc {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 80%;
}
#wit-list .details-interest {
  display: flex;
  flex-direction: row;
}
#wit-list .parabolded {
  font-weight: bold;
  padding-right: 5px;
  color: white;
  margin: 2px;
  font-size: 15px;
  font-family: Helvetica, sans-serif;
}
#wit-list .details-name {
  margin-top: 5px;
  height: fit-content;
}

#wit-list .details-name-header {
  color: white;
  margin: auto;
  font-weight: bold;
  font-size: 29px;
}
#wit-list .para1 {
  color: white;
  margin: 2px;
  font-size: 15px;
  font-family: Helvetica, sans-serif;
}

#wit-list .para {
  color: white;
  font-size: 13px;
  font-family: Helvetica, sans-serif;
}

#wit-list .accordion-button:not(.collapsed) {
  color: white !important;
  background-color: #532b71 !important;
}

#wit-list .accordion-header {
  font-weight: normal !important;
}

#wit-list .accordion-button:hover {
  background-color: #532b71;
  color: white;
}

#wit-list .accordion-button::after {
  visibility: hidden;
  background-image: "url(/public/arrow-right.png)" !important;
}

#wit-list .accordion-button:hover:after {
  visibility: visible !important;
}

#wit-list .accordion-button:not(.collapsed)::after {
  visibility: visible !important;
}

#wit-list .filter_dropdown {
  cursor: pointer;
}

#wit-list .filter_dropdown_menu {
  min-width: 15rem;
  padding: 0px;
  width: 150%;
}

#wit-list .sortingOrder {
  cursor: pointer;
}
#wit-list .sortingOrder:hover {
  text-decoration: underline !important;
}

#wit-list .imgprofile {
  width: 207px;
  height: 207px;
  max-width: 207px;
  max-height: 207px;
  object-fit: cover;
}

#wit-list .img {
  margin: 10px;
}

#wit-list .pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 15px !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  position: relative !important;
}

/* #wit-list .paginationItem {
    background: #fff;
    border: 2px solid #666;
    padding: 10px 15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
  }
  
  #wit-list .paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */

/* #wit-list .prev,.next {
    background: rgb(83, 43, 113, 1);
    border: none;
    padding: 10px;
    color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  } */

/* #wit-list .paginationItem.active {
    border: 1px solid #888;
    color: #888;
    pointer-events: none;
  }
  
  #wit-list .prev.disabled,
  #wit-list .next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
  } */

#wit-list .mobilePara {
  display: none;
}

#wit-list .dropdown-toggle::after {
  display: none !important;
}

.page-link {
  color: #532b71 !important;
}

.page-item.active .page-link {
  background-color: white !important;
  border-color: #532b71 !important;
}

#wit-list .descpara {
  font-size: 16px;
}

#wit-list .accordion::-webkit-scrollbar {
  display: none !important;
}

#wit-list .accordion {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 768px) {
  #wit-list .redHr {
    visibility: hidden;
  }
  #wit-list .details-name-header {
    color: white;
    margin: auto;
    font-weight: bold;
    font-size: 27px;
  }
  #wit-list .para1 {
    color: white;
    margin: 2px;
    font-size: 14px;
    font-family: Helvetica, sans-serif;
  }

  #wit-list .para {
    color: white;
    font-size: 14px;
    font-family: Helvetica, sans-serif;
  }

  #wit-list .parabolded {
    font-weight: bold;
    padding-right: 5px;
    color: white;
    margin: 2px;
    font-size: 14px;
    font-family: Helvetica, sans-serif;
  }

  #wit-list .mobilePara {
    display: block;
    padding: 50px;
    padding-top: 0px;
  }

  #wit-list .desktopPara {
    display: none;
  }

  #wit-list .detail-div {
    background-color: #532b71;
    display: flex;
    flex-direction: column;
  }

  #wit-list .desktopRead {
    display: none;
  }

  #wit-list .imgprofile {
    width: 165px;
    height: 165px;
    border-width: 2px !important;
    object-fit: cover;
  }
}

#wit-details .Profile {
  display: flex;
  flex-direction: column;
}
#wit-details .Profile-header {
  display: flex;
  flex-direction: row;
}
#wit-details .ProfileDetails {
  display: flex;
  flex-direction: column;
}
#wit-details .ProfileInterest {
  display: flex;
  flex-direction: row;
}
#wit-details .parabold {
  font-weight: bold;
  padding-right: 5px;
  color: #532b71;
  margin: 2px;
  font-size: 15px;
}
#wit-details .ProfileName {
  background-color: #532b71;
  padding-right: 60px;
  margin-top: 5px;
  height: fit-content;
  max-width: 90%;
}
#wit-details .ProfileNameHeader {
  color: white;
  margin: auto;
  padding-left: 5px;
  font-size: 37px;
  font-weight: bold;
}
#wit-details .purplepara {
  color: #532b71;
  margin: 2px;
}

#wit-details .purplepara1 {
  color: #532b71;
  margin: 2px;
  font-size: 18px;
}

#wit-details .person-img {
  width: 300px;
  height: 300px;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
}

#wit-details .center-img {
  max-width: 300px;
  max-height: 300px;
}

#wit-details .paginationItem:disabled {
  background-color: gray;
  color: gray;
}

.page-item.disabled .page-link {
  background-color: #ebebe4 !important;
  border-color: #dee2e6 !important;
}

@media (max-width: 768px) {
  #wit-details .center-img {
    text-align: center !important;
    padding: none !important;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    height: 100%;
    max-width: none !important;
    max-height: none !important;
  }
  #wit-details .person-img {
    width: 265px !important;
    height: 265px !important;
    max-width: 265px !important;
    max-height: 265px !important;
  }
  #wit-details .ProfileName {
    max-width: 100% !important;
  }
  #wit-details .ProfileInterest {
    padding-bottom: 10px;
  }

  #wit-details .ProfileNameHeader {
    font-size: 35px;
  }

  #wit-details .img {
    width: 165px !important;
    height: 165px !important;
    max-width: 165px !important;
    max-height: 165px !important;
  }
}
