body {
  background: #fff !important;
  min-height: 100vh;
}

.App {
  position: relative;
  min-height: 100vh;
}

/* Responsive Images */
img {
  max-width: 100%;
}

.PhoneInput input {
  border: 0;
}

#splash-2022 li:hover {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/files-scs-prod/public%2Fimages%2F1652173153424-Path+645.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 40px;
}

.form-select-override {
  padding: 0.5rem 0.5rem 0.75rem 1rem !important;
}

.dp-custom-style {
  border-width: 1px;
  border-radius: 5px;
  border-color: #ced4da;
  height: 45px;
  padding-left: 14px;
}

.icon-nav-container {
  background-color: #EDEDED;
  border-radius: 5px;
  margin: 30px 50px;
  padding-top: 10px;
  padding-bottom: 10px;  
}

.icon-nav-controller {
  display: flex;
  align-items: center;
  justify-content: space-around;     
  cursor: pointer; 
  
  
}

.icon-nav-label {
  font-weight: 500;
  font-size: 13px;
  color: "#000";  
  padding-top: 18px;
  padding-right: 5px;  
  word-spacing: 5px;
  /* white-space: nowrap;   */
}

.icon-nav-asset {
  width: 35px;
  height: 35px;
}

.tab-nav {
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.tab-nav::-webkit-scrollbar {
  display: none;
}

.tab-list {
  width: fit-content; 
  min-width: 100%;
  color: #00418b;
  display: flex;
  gap: 0.5em;
  border-bottom: 2px solid #99BBD7;
  justify-content: space-between; 
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
}

.tab-list li {
  flex: 1; 
  display: flex;
  justify-content: center; 
  margin-bottom: -2px;
}

.tab-btn {
  font: inherit;
  padding: 0.75em 1em;
  background-color: transparent;
  border: 2px solid #99BBD7;
  border-width: 0 0 2px;
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
  width: 100%; 
  text-align: center;
}

.tab-btn:not(.tab-btn--active):hover {
  color: #00418b;
}

.tab-btn--active {
  color: #00418b;
  border-color: #00418b;
}

.tab-content {
  margin-top: 1em;
  padding: 1em;
  background-color: #f1f2f6;
  border-radius: 0.5em;
}

.tab-panel {
  display: none;
}

.tab-panel--active {
  display: block;
}

.tab-panel--hidden {
  display: none;
}

.light-blue-bg {
  background-color: #D1E3FF;
}