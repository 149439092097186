.footer {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 30px;
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 30px;
}

.footerTitle {
  font-size: 18px;
  font-weight: 500;
}

.footerLinks {
  padding-left: 0;
  margin-top: 30px;
  list-style: none;
}

.footerLinks li {
  margin-bottom: 10px;
}

.footerLink {
  text-decoration: none;
  color: #353535;
}

.footerText {
  text-align: left;
  margin-right: 25px;
}

@media only screen and (min-width: 768px) {
  .footer {
    margin-top: 50px;
    background-color: #f2f2f2;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .footerText {
    text-align: right;
    margin-right: 0;
  }
}
