$primary: #004c98;

@import "~bootstrap/scss/bootstrap";
@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";

:root {
  --primary-color: #{$primary};
}

a:not([class*="btn"]) {
  text-decoration: none;
}
a:not([class*="btn"]):not([class*="nav-link"]):hover {
  text-decoration: underline;
}

.nav-link {
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
}

#scrollTop {
  background: #00418b;
  display: none;
  position: fixed;
  bottom: 45px;
  right: 0px;
  padding: 2px;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
}

// .navbarMobile .dropdown .dropdown-menu {
//   padding: 0;
//   margin: 0;
//   border: 0;
//   border-radius: 0;
// }
// .navbarMobile .dropdown-toggle {
//   color: white !important;
//   background: #023064;
// }
// .navbarMobile .dropdown .dropdown .dropdown-toggle {
//   color: white !important;
//   background: #00418b !important;
// }
// .navbarMobile .dropdown-menu .dropdown-menu {
//   background: white;
// }
// @media (max-width: 1023px) {
//   .navbarMobile .nav-link {
//     color: white !important;
//   }
//   .navbarMobile .navbar-collapse {
//     position: absolute;
//     top: 79px;
//     right: 0;
//     width: 100%;
//     height: 0;
//   }
//   .navbarMobile .navbar-collapse.collapsing {
//     height: 0 !important;
//     -webkit-transition: right 0.3s ease-out;
//     -o-transition: right 0.3s ease-out;
//     -moz-transition: right 0.3s ease-out;
//     transition: right 0.3s ease-out;
//     right: -110%;
//     z-index: 99999;
//   }
//   .navbarMobile .navbar-collapse.show {
//     background: #00418b;
//     width: 300px;
//     height: 100vh;
//     right: 0;
//     -webkit-transition: right 0.3s ease-in;
//     -o-transition: right 0.3s ease-in;
//     -moz-transition: right 0.3s ease-in;
//     transition: right 0.3s ease-in;
//     z-index: 99999;
//   }
// }
.text-letter-spacing-1 {
  letter-spacing: 1.5px !important;
}

.text-letter-spacing-2 {
  letter-spacing: 3px !important;
}

.navlink:hover {
  color: #344d76 !important;
}

.navbar:hover + .nav-sibling {
  display: block;
  opacity: 0.5;
  background: rgba(0, 0, 0, 1);
  transition: opacity 0.15s linear;
}

.nav-sibling {
  position: fixed;
  display: none;
  overflow: hidden;
  outline: 0;
  /* equal to the navbar height */
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebar {
  width: 230px;
  color: #333;
  padding: 20px 30px;
  background: #dedede;
  border-radius: 5px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 5%;
}

.sidebar a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
}
.sidebar li {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #aaa;
}
.sidebar li:last-child {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: none;
}

.sidebar li:first-child {
  border-bottom: 1px solid #aaa;
}

.sidebar a:hover {
  text-decoration: underline;
}

.sidebar li.is-current a {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .excoFlex {
    flex-grow: 1;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .excoFlex {
    flex-grow: 1;
    flex-basis: 33%;
    max-width: 33%;
  }
}

.increaseFontSize {
  font-size: 18px !important;
}

.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.full-page-loader > img {
  animation: 1.8s infinite heartbeat;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.body-of-knowledge {
  @font-face {
    font-family: "Georgia";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Bold";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Italic";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Italic Bold";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Regular";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  * .header {
    &::before {
      left: 0;
      content: "";
      z-index: -1;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: 100% 120%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(https://files-scs-prod.s3.ap-southeast-1.amazonaws.com/public/bok/ai-ethics/bg.png);
    }

    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  * .header-v-1 {
    &::before {
      left: 0;
      content: "";
      z-index: -1;
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url(https://files-scs-prod.s3.ap-southeast-1.amazonaws.com/public/bok/ai-ethics-v1-1/bg.png);
    }

    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  * .header-v-2 {
    &::before {
      left: 0;
      content: "";
      z-index: -1;
      object-fit: fill;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(https://files-scs-prod.s3.ap-southeast-1.amazonaws.com/public/bok/ai-ethics-v2-0/bg.png);
    }

    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .header-text {
    margin-top: 90px;
  }

  .header-text h1 {
    font-weight: 200;
    font-size: calc(0.4em + 1vw);
    margin-bottom: calc(0.4em + 1vw);
    color: #fff;
  }

  .header-text h1 strong {
    font-weight: 700;
  }

  .header-text p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 40px;
    color: #fff;
  }

  .header-text p span {
    font-weight: 600;
  }

  .header-image-lg {
    width: 1130px;
    margin: 0 0 0 -110px;
  }

  .header .header-img img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  @media (min-width: 992px) {
    .header {
      text-align: left;
    }
    .header .header-img {
      width: 1140px;
      margin-left: -109px;
    }
    .header-content {
      padding-top: 110px;
    }
    .header-text h1 {
      font-size: 36px;
    }
  }

  * .document {
    color: #000000;
    font-family: "Georgia", sans-serif;
    font-size: 16px;
  }

  * .font-17 {
    font-size: 17px;
  }

  * .doc-content {
    line-height: 1;

    * {
      word-wrap: break-word;
    }
  }

  * #authors > .dropdown-menu.show,
  * #reviewers > .dropdown-menu.show {
    overflow-y: scroll;
    max-height: 300px;
  }

  * .boxBg1 {
    background-color: #4d5dfb;
    background-image: linear-gradient(315deg, #4d5dfb 0%, #08c8f6 74%);
  }

  * .boxBg2 {
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
  }

  * .boxBg3 {
    background-color: #70b2d9;
    background-image: linear-gradient(315deg, #70b2d9 0%, #39e5b6 74%);
  }

  * .boxBg4 {
    background-color: #d1e3ff;
  }

  * .boxBg5 {
    background-color: #4d5dfb;
    background-image: linear-gradient(
      180deg,
      #f2ceff 0%,
      #dedcff 52.5%,
      #d3e4ff 100%
    );
  }

  * .feature-box {
    &:hover {
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      transform: translateY(-10px);

      * .details {
        opacity: 1;
      }

      .widget {
        top: 0;
      }
    }

    display: table;
    position: relative;
    overflow: hidden;
    padding: 20px;
    width: 100%;
    height: 90%;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 2rem;
    transition: all 0.3s ease-in-out;

    .widget {
      height: 100%;
      vertical-align: middle;
      padding-bottom: 30px;
      position: relative;
      top: 30px;
    }

    * .details {
      opacity: 0;
    }

    * a {
      &:hover {
        text-decoration: none;
      }
    }

    * {
      transition-timing-function: ease-in-out;
      -ms-transition-timing-function: ease-in-out;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      -ms-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      -webkit-transition-duration: 0.2s;
      -o-transition-duration: 0.2s;
    }
  }

  .doc-content a {
    text-decoration: underline;
  }
  .doc-content {
    line-height: 1;
  }
  .doc-content h4 {
    color: red;
    font-weight: bolder;
  }
  .doc-content h5,
  h6 {
    color: #000;
    font-weight: bolder;
  }

  .red {
    color: red;
  }

  .font-17 {
    font-size: 17px !important;
  }
  .font-19 {
    font-size: 19px !important;
  }
  .doc-content img {
    max-width: 100%;
  }
  .watermarked {
    position: relative;
    z-index: 0;
  }
  .watermarked:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/logo-watermark.png");
    background-repeat: repeat-y;
    background-size: contain;
    opacity: 0.7;
    z-index: -1;
    /* transform: rotate(30deg);
    transform-origin: top left; */
  }
  .watermarked-resources {
    position: relative;
    z-index: 0;
  }
  .watermarked-resources:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/logo-watermark.png");
    background-repeat: repeat-y;
    background-size: cover;
    opacity: 0.7;
    z-index: -1;
    /* transform: rotate(30deg);
    transform-origin: top left; */
  }
  .truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    max-height: 4.5em;
    font-family: "Roboto", sans-serif;
    text-align: justify;
  }
  .highligted-text {
    background-color: yellow;
  }
  .search-result-text-title {
    font-size: 1.75rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-top: 1rem !important;
  }
  .search-result-text-author {
    font-family: "Roboto", sans-serif;
    font-style: italic;
  }
  .bok-tag {
    background-color: #e5e5e5;
    border-radius: 20px;
    padding: 3px 10px;
    color: #000000;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 0.9em;
    margin: 3px;
  }
  .bok-tag:hover {
    background-color: #343d4b;
    color: #ffffff;
    cursor: pointer;
  }
  .tags-text {
    font-family: "Roboto", sans-serif;
  }
  .bok-search-border {
    border: 1;
    height: 1.5px;
    background-color: transparent; /* Remove default background color */
    border-top: 1.5px solid #004c98; /* Custom color */
  }
  .search-bok:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  button.js-scroll-trigger.btn.btn-link {
    text-align: left;
  }
  .section-title h2 {
    font-size: 28px;
    font-weight: 500;
  }
  ul.section-nav,
  ul.section-nav ul,
  ul.section-nav li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.section-nav ul li {
    padding-left: 10px;
    text-align: left;
  }

  ul.section-nav ul li a {
    display: flex;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #253858;
    margin-bottom: 0.8rem;
    position: relative;
    font-family: "Georgia", sans-serif;
  }

  .btn-outline {
    &:hover {
      text-decoration: underline !important;
    }

    color: $primary;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px !important;
    box-shadow: inset 0 0 0 1px $primary;
    text-decoration: none !important;
  }

  .section-title {
    text-align: center;
    margin-bottom: 50px;
  }
  .section-title h2 {
    font-size: 28px;
    font-weight: 500;
  }
  .section-title p {
    font-size: 17px;
  }
  .service-content {
    padding: 70px 0;
  }
  .register-content {
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .service-box,
  .box-topic {
    text-align: center;
    padding: 50px 35px;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 4px;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.07);
  }
  .service-box:hover,
  .box-topic:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.09);
  }
  .service-box .title,
  .box-topic .title {
    font-weight: 700;
    font-size: 20px;
  }
  .service-box .icon,
  .box-topic .icon {
    margin-bottom: 10px;
  }
  .service-box .icon i,
  .box-topic .icon i {
    font-size: 60px;
    color: #00418b;
  }

  .user-content {
    padding: 70px 0;
  }
  .user-logo {
    margin-bottom: 70px;
  }
  .logo-item {
    margin: 0;
    padding: 1.5rem 1rem;
    text-align: center;
  }
  @media (max-width: 575px) {
    .logo-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (min-width: 576px) {
    .logo-item {
      border: solid #c7c7c7;
      border-width: 0 1px 0 0px;
    }
    .logo-item:last-child {
      border-right: none;
    }
  }
  @media (min-width: 1200px) {
    .logo-item img {
      height: 30px;
    }
  }

  .acca {
    height: 35px !important;
  }

  .ibf {
    height: 30px !important;
  }

  .sal {
    height: 40px !important;
  }

  .smf {
    height: 50px !important;
  }

  .bg-gray {
    background-color: #f4f5f7;
  }

  .sticky-top {
    z-index: unset !important;
  }
}

.body-of-knowledge-v1-1 {
  @font-face {
    font-family: "Georgia";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Bold";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Italic";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Italic Bold";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Georgia-Regular";
    src: url("/assets/fonts/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  * .header {
    &::before {
      left: 0;
      content: "";
      z-index: -1;
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      background-image: url(https://files-scs-prod.s3.ap-southeast-1.amazonaws.com/public/bok/ai-ethics-v1-1/bg.png);
    }

    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .header-text {
    margin-top: 90px;
  }

  .header-text h1 {
    font-weight: 200;
    font-size: calc(0.4em + 1vw);
    margin-bottom: calc(0.4em + 1vw);
    color: #fff;
  }

  .header-text h1 strong {
    font-weight: 700;
  }

  .header-text p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 40px;
    color: #fff;
  }

  .header-text p span {
    font-weight: 600;
  }

  .header-image-lg {
    width: 1130px;
    margin: 0 0 0 -110px;
  }

  .header .header-img img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  @media (min-width: 992px) {
    .header {
      text-align: left;
    }
    .header .header-img {
      width: 1140px;
      margin-left: -109px;
    }
    .header-content {
      padding-top: 110px;
    }
    .header-text h1 {
      font-size: 36px;
    }
  }

  * .document {
    color: #000000;
    font-family: "Georgia", sans-serif;
    font-size: 16px;
  }

  * .font-17 {
    font-size: 17px;
  }

  * .doc-content {
    line-height: 1;

    * {
      word-wrap: break-word;
    }
  }

  * #authors > .dropdown-menu.show,
  * #reviewers > .dropdown-menu.show {
    overflow-y: scroll;
    max-height: 300px;
  }

  * .boxBg1 {
    background-color: #4d5dfb;
    background-image: linear-gradient(315deg, #4d5dfb 0%, #08c8f6 74%);
  }

  * .boxBg2 {
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
  }

  * .boxBg3 {
    background-color: #70b2d9;
    background-image: linear-gradient(315deg, #70b2d9 0%, #39e5b6 74%);
  }

  * .boxBg4 {
    background-color: #d1e3ff;
  }

  * .boxBg5 {
    background-color: #4d5dfb;
    background-image: linear-gradient(
      180deg,
      #f2ceff 0%,
      #dedcff 52.5%,
      #d3e4ff 100%
    );
  }

  * .feature-box {
    &:hover {
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      transform: translateY(-10px);

      * .details {
        opacity: 1;
      }

      .widget {
        top: 0;
      }
    }

    display: table;
    position: relative;
    overflow: hidden;
    padding: 20px;
    width: 100%;
    height: 90%;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 2rem;
    transition: all 0.3s ease-in-out;

    .widget {
      height: 100%;
      vertical-align: middle;
      padding-bottom: 30px;
      position: relative;
      top: 30px;
    }

    * .details {
      opacity: 0;
    }

    * a {
      &:hover {
        text-decoration: none;
      }
    }

    * {
      transition-timing-function: ease-in-out;
      -ms-transition-timing-function: ease-in-out;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      -ms-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      -webkit-transition-duration: 0.2s;
      -o-transition-duration: 0.2s;
    }
  }

  .doc-content a {
    text-decoration: underline;
  }
  .doc-content {
    line-height: 1;
  }
  .doc-content h4 {
    color: red;
    font-weight: bolder;
  }
  .doc-content h5,
  h6 {
    color: #000;
    font-weight: bolder;
  }

  .red {
    color: red;
  }

  .font-17 {
    font-size: 17px !important;
  }
  .font-19 {
    font-size: 19px !important;
  }
  .doc-content img {
    max-width: 100%;
  }

  .section-title h2 {
    font-size: 28px;
    font-weight: 500;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #253858;
    margin-bottom: 0.8rem;
    position: relative;
    font-family: "Georgia", sans-serif;
  }

  .btn-outline {
    &:hover {
      text-decoration: underline !important;
    }

    color: $primary;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px !important;
    box-shadow: inset 0 0 0 1px $primary;
    text-decoration: none !important;
  }

  .section-title {
    text-align: center;
    margin-bottom: 50px;
  }
  .section-title h2 {
    font-size: 28px;
    font-weight: 500;
  }
  .section-title p {
    font-size: 17px;
  }
  .service-content {
    padding: 70px 0;
  }
  .register-content {
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .service-box,
  .box-topic {
    text-align: center;
    padding: 50px 35px;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 4px;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.07);
  }
  .service-box:hover,
  .box-topic:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.09);
  }
  .service-box .title,
  .box-topic .title {
    font-weight: 700;
    font-size: 20px;
  }
  .service-box .icon,
  .box-topic .icon {
    margin-bottom: 10px;
  }
  .service-box .icon i,
  .box-topic .icon i {
    font-size: 60px;
    color: #00418b;
  }

  .user-content {
    padding: 70px 0;
  }
  .user-logo {
    margin-bottom: 70px;
  }
  .logo-item {
    margin: 0;
    padding: 1.5rem 1rem;
    text-align: center;
  }
  @media (max-width: 575px) {
    .logo-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (min-width: 576px) {
    .logo-item {
      border: solid #c7c7c7;
      border-width: 0 1px 0 0px;
    }
    .logo-item:last-child {
      border-right: none;
    }
  }
  @media (min-width: 1200px) {
    .logo-item img {
      height: 30px;
    }
  }

  .acca {
    height: 35px !important;
  }

  .ibf {
    height: 30px !important;
  }

  .sal {
    height: 40px !important;
  }

  .smf {
    height: 50px !important;
  }

  .bg-gray {
    background-color: #f4f5f7;
  }

  .sticky-top {
    z-index: unset !important;
  }
}

.author-title-box {
  margin-bottom: -7px;
  border-style: inset;
  border: 3px solid #cc8a21;
  border-radius: 5px 5px 0px 0px;
  border-color: #edc98c;
  padding: 2%;
  background-color: moccasin;
  font-weight: 1000;
}

.author-title-content {
  font-family: Georgia;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.author-content-box {
  border-style: solid;
  border-radius: 7px;
  border-color: #edc98c;
  padding: 2%;
  background-color: blanchedalmond;
  margin-bottom: 20px;
}

.author-contents {
  font-family: Georgia;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.chapter-end {
  margin: 40px;
  font-weight: bold;
}

.sidebar-menu-text-custom {
  a,
  a:hover,
  a:focus {
    color: #ffffff;
  }
}

.my-modal {
  width: 60vw;
  max-width: 60vw;
}

.font-link {
  text-decoration: underline !important;
  font-weight: bold;
}

.text-justify {
  text-align: justify;
}
