.sticky-widget {
  position:fixed;
  bottom:270px;
  right:0;
}

.sticky-widget .label {
  padding:5px 15px;
  font-weight: bold;
  background:#00418B;
  transform-origin: bottom right;
  transform: rotate(-90deg);
  white-space: nowrap;
  color:#fff;
  text-decoration:none;
}

.sticky-widget a {
  color:#fff;
  text-decoration:none;
}

.sticky-widget a:hover {
  color:#fff;
  text-decoration:none;
}